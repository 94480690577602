import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-modal"
// pass 'default_close: true' to the modal to prevent the entire modal from closing on a button press.
// pass data:{ close: true } in the form element to stop only that form from closing after submit
export default class extends Controller {
    static targets =["form"]
    static values = {
        update: Boolean
    }

    connect() {
    }

    copy() {
        this.dispatch("copy", { detail: { content: this.sourceTarget.value } })
        navigator.clipboard.writeText(this.sourceTarget.value)
    }

    closeModal(e) {
        this.element.remove();
    }

    delayCloseModal(event) {
        setTimeout(() => this.closeModal(), 100);
    }

    submit(event) {
        console.log(event.target.dataset.close);
        if (event.target.dataset.close == 'true') { return; }
        if(!this.updateValue || event.target.dataset.close == 'false') {
            setTimeout(() => this.closeModal(), 200);
        }
    }

    submitOnly(e) {
        e.preventDefault();
        e.target.form.requestSubmit();
    }

    submitClose(e) {
        e.preventDefault();
        e.target.form.requestSubmit();

        setTimeout(() => this.closeModal(), 200);
    }

    disableButton(e) {
        e.target.disabled = true;
        e.target.innerText = "Submitting...";
        this.formTarget.requestSubmit();
    }
}