// use this controller on a form to submit it on an action e.g. data: { controller: "form-submit", action:"input->form-submit#submit"}
// OR
// add it to a button to submit a specific form  by Id e.g. data: { controller: 'form-submit', form_submit_form_id_value: 'form_1', , action:"input->form-submit#submit"}
// this will submit the form with the id 'form_1'

import { Controller } from "@hotwired/stimulus";
import { debounce } from '../pages/utilities';

export default class extends Controller {
    static values = { formId: String}
    form = null;

    connect() {
        if(this.hasFormIdValue) { this.form = document.getElementById(this.formIdValue); }
    }
    initialize() {
        this.submit = debounce(this.submit.bind(this), 250)
    }

    test() {
        this.form.requestSubmit();
    }
    submit() {
        if (this.form) {
            this.form.requestSubmit();
        } else {
            console.log(this.element);

            this.element.requestSubmit();
        }
    }
}