import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import {FetchRequest, patch} from "@rails/request.js";

const DEFAULT_RESPONSE_KIND = 'turbo_stream';

export default class extends Controller {
    static values = {
        responseKind: {
            type: String,
            default: 'html'
        },
        animation: {
            type: Number,
            default: 150
        },
        handle: String,
        group: {
            type: String,
            default: 'group'
        },
        params: {
            type: Object,
            default: {}
        }
    }

    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.onEnd.bind(this),
            animation: 150,
            handle: '.sortable-handle',
            group: {
                name: this.groupValue,
                put: this.groupValue
            },
        })
    }

    async onEnd ({ item, newIndex }) {
        if (!item.dataset.sortableUpdateUrl) return; //return if updateUrl hasn't been set
        let URL = item.dataset.sortableUpdateUrl;

        if (this.hasParamsValue) {
            let params = Object.entries(this.paramsValue).map(([key, val]) => `${key}=${val}`).join('&');
            URL += `?${params}`;
        }

        let dropContainer = item.closest('.drop-container'); //set the container element we are dropping into

        const data = new FormData();
        data.append('position', newIndex + 1); // new position oof item dropped


        if(dropContainer) { data.append('container_id', dropContainer.dataset.id); } //add the id of the container we dropped into, as a param

        const responseKind = item.dataset.responseKind ? item.dataset.responseKind : DEFAULT_RESPONSE_KIND;
        return await patch(URL, { body: data, responseKind: responseKind });
    }
}